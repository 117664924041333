module.exports = {
  siteTitle: "Hi! I'm Emilio!",
  siteDescription: `This is my personal website`,
  keyWords: ['devOps', 'Kubernetes', 'Curriculum', '42'],
  authorName: 'Emilio García',
  twitterUsername: 'egarcia',
  githubUsername: 'byrd92',
  authorAvatar: '/images/avatar.jpg',
  authorDescription: `Hi! My name is <strong>Emilio</strong> and I'm a <strong>SRE Engineer</strong>. 
  Performance-driven and dedicated devops engineer with more than 4 years in the industry. <br/><br/>`,
  skills: [
    {
      name: 'Kubernetes',
      level: 99,
    },
    {
      name: 'Docker',
      level: 95,
    },
    {
      name: 'Databases',
      level: 88,
    },
    {
      name: 'Linux System',
      level: 90,
    },
    {
      name: 'CI/CD Tools',
      level: 100,
    },
    {
      name: 'Git',
      level: 90,
    },
    {
      name: 'Azure',
      level: 90,
    }
  ],
  jobs: [
      {
      company: 'Mercedes Benz',
      begin: {
        month: 'Jul',
        year: '2021',
      },
    duration: '1 year',
    location: 'Madrid',
    occupation: 'DevOps Engineer',
    description:
      'Creation of automation tools, improvement of existing network operations, maximice eficiency and security, maintain company software and hardware',
    },
    {
      company: 'Deimos Space',
      begin: {
        month: 'Jul',
        year: '2020',
      },
    duration: '1 year',
    location: 'Madrid',
    occupation: 'DevOps Engineer',
    description:
      'Manage and customize all basic CI/CD Tools, Monitoring, Automation and use of Agile methodology in the company. Docker, Kubernetes, Grafana, Prometheus, Git, Jira, Sonarqube, Nexus, etc',
    },
    {
      company: 'Cegadrone',
      begin: {
        month: 'Feb',
        year: '2019',
      },
      duration: '2 year',
      location: 'Madrid',
      occupation: 'IT Manager & Customer Consulting',
      description:
        'Managed all our tecnological resources (UAVS systems, Software, Maintenance, Databases, Reporting Tools, Visual inspector Tools, etc) and developed new organization systems to improve efficiency with new customers.',
    },
    {
      company: 'Cegadrone',
      begin: {
        month: 'May',
        year: '2017',
      },
      duration: '2 year',
      location: 'Madrid',
      occupation: 'Data Analyst & Business Ingelligence Developer',
      description:
        'Worked as experienced data professional who can  query and process data, provide reports, summarize and visualize data. Tools: excel, SQL, GIS (ARCGis, Qgis, G.earth). I develop and support new and existing data warehouses, ETL packages,  dashboards and analytical reports.',
    },
    
  ],
  publications: [
    {
      title: '',
      company_medium: '',
      date: {
        month: 'jun',
        year: '2019',
      },
      link: '',
      description:
        '',
    },
  ],
  education: [
    {
      school: '42 Madrid',
      degree: 'Higher Education',
      field: '\n Adaptation & Creativity , Algorithm & AI, DB & Data, Security, Shell, Network, Funtional Programming, Object Oriented programming, Web.',
      startYear: 2019,
      endYear: 2023,
    },
    {
      school: 'UNED University',
      degree: 'Higher Education',
      field: 'OS System Administrator, Database Admin, Shellscript, Network Services, Web API',
      startYear: 2018,
      endYear: 2021,
    },
    {
      school: 'CES Higher School',
      degree: 'Higher Education',
      field: 'Sound engineer',
      startYear: 2012,
      endYear: 2014,
    },
  ],
  languages: [
    {
      language: 'Spanish',
      level: 'Native tongue',
      code: 'ES',
    },
    {
      language: 'English',
      level: 'Fluid Written & Spoken',
      code: 'GB',
    },
  ],
  social: {
    linkedin: 'https://www.linkedin.com/in/emilio-garc%C3%ADa92/',
    github: 'https://github.com/byrd92',
    email: 'emilioggo@gmail.com',
  },
  hobbies: [
    {
      name: 'Music',
      icon: 'GiSaxophone',
    },
    {
      name: 'Gaming',
      icon: 'GiGamepad',
    },
    {
      name: 'Computing',
      icon: 'GiComputing',
    },
    {
      name: 'Movies & Series',
      icon: 'GiFilmStrip',
    },
  ],
  siteUrl: 'http://emigarcia.me',
  pathPrefix: '/gatsby-starter-cv', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: '',
  background_color: '#c5c3c6f',
  theme_color: '#25303B',
  display: 'minimal-ui',
  icon: 'src/favicon.jpg',
  resume: 'https://drive.google.com/file/d/1b6kHEqqbja9uPct58ppL47PdWGxeAqQk/view?usp=share_link',
  headerLinks: [

    {
      label: 'About',
      url: 'about',
    },
    {
      label: 'Skills',
      url: 'skills',
    },
    {
      label: 'Experience',
      url: 'experience',
    },
    {
      label: 'Github',
      url: 'projects',
    },
    {
      label: 'Education',
      url: 'education',
    },
    {
      label: 'Hobbies',
      url: 'hobbies',
    },
  ],
}
